<template>
  <v-card class="ma-3" :style="view === 'table' ? 'min-height:1043px' : ''">
    <v-toolbar>
      <v-toolbar-title>Library Archives</v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="mr-2">View:</span>
      <v-btn-toggle v-model="view" dense class="mr-4">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" value="table" aria-label="View as Table">
              <v-icon>fal fa-table</v-icon>
            </v-btn>
          </template>
          <span>View as a table</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" value="card" aria-label="View as Cards">
              <v-icon>fal fa-id-card</v-icon>
            </v-btn>
          </template>
          <span>View as cards</span>
        </v-tooltip>
      </v-btn-toggle>
      <v-btn to="/library/archives" class="mr-4" exact>Archive Categories</v-btn>
      <v-btn v-if="isAdmin" outlined @click="addEntry">
        <v-icon left>fal fa-plus</v-icon>
        Add Entry
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col v-if="$route.params.category === 'All'">
          <v-autocomplete v-model="category" :items="categories" label="Category" outlined></v-autocomplete>
        </v-col>
        <v-col v-if="category === 'Capstone'">
          <v-autocomplete v-model="capstoneMajor" :items="capstoneMajors" label="Major" outlined multiple chips small-chips deletable-chips>
            <template v-slot:item="{ item }">
              <v-list-item-action>
                <v-checkbox :value="capstoneMajor.includes(item.value)"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.count }} {{ item.count === 1 ? 'Entry' : 'Entries' }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field v-model="search" label="Search" hint="Searches only complete words" persistent-hint outlined clearable></v-text-field>
        </v-col>
        <v-col>
          <v-autocomplete v-model="yearFilter" :items="yearOptions" label="Academic Year" outlined multiple chips small-chips deletable-chips>
            <template v-slot:item="{ item }">
              <v-list-item-action>
                <v-checkbox :value="yearFilter.includes(item.value)"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.matchCount != null">Matched {{ item.matchCount }} out of {{ item.count }} {{ item.count === 1 ? 'Entry' : 'Entries' }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>{{ item.count }} {{ item.count === 1 ? 'Entry' : 'Entries' }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-alert v-if="category === 'Chapel'" type="info" colored-border border="left" elevation="3" class="mt-2">Chapels from 1971-2003 were originally recorded on audio cassette tapes. Any cracking, popping, or background noise is on the original master.  Some recordings may not contain the last few minutes of the service due to the length of the tape used.</v-alert>
    </v-card-text>
    <v-data-table v-if="view === 'table'" :items="entries" :headers="headers" :loading="loading" :server-items-length="entryCount" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" hide-default-footer disable-pagination @dblclick:row="(e, { item }) => downloadItem(item)">
      <template v-slot:item.thumbnail="{ item }">
        <v-card elevation="1" class="pa-0" style="width:48px;">
          <v-img :src="item.thumbnail || '/img/icons/msapplication-icon-144x144.png'" contain style="width:48px;height:64px" />
        </v-card>
      </template>
      <template v-slot:item.date="{ item }">{{ stringFormatDate(item.date, true, true) }}</template>
      <template v-slot:item.format="{ item }">
        <v-tooltip v-if="item.format === 'pdf'" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">fal fa-file-pdf</v-icon>
          </template>
          <span>Format: PDF</span>
        </v-tooltip>
        <v-tooltip v-else-if="item.format === 'mp3'" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">fal fa-volume</v-icon>
          </template>
          <span>Format: MP3</span>
        </v-tooltip>
        <span v-else>{{ item.format }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-menu v-if="isAdmin">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>fal fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item :to="'/library/archives/' + item._id" :disabled="!('fileLink' in item)">
              <v-list-item-avatar>
                <v-icon>fal fa-eye</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>View File</v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="!('fileLink' in item)" :href="'https://drive.google.com/uc?export=download&id=' + item.fileLink">
              <v-list-item-avatar>
                <v-icon>fal fa-download</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Download File</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isAdmin" @click="editItem(item)">
              <v-list-item-avatar>
                <v-icon>fal fa-pencil</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Edit Entry</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-else-if="'fileLink' in item" @click="downloadItem(item)" aria-label="View File">
          <v-icon>fal fa-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-card-text v-else-if="view === 'card'">
      <v-row class="pl-4 pr-4 pt-4 pb-2">
        <v-col v-for="{ _id, title, date, description, thumbnail, fileLink } of entries" :key="_id" cols="12" md="6" lg="4" xl="3" class="pa-0">
          <v-card class="ma-2 entry-card">
            <v-list-item @click="isAdmin ? editItem({ _id }) : downloadItem({ _id, title, fileLink })">
              <v-list-item-action>
                <v-card elevation="3" class="pa-0" style="width:48px;">
                  <v-img :src="thumbnail || '/img/icons/msapplication-icon-144x144.png'" contain style="width:48px;height:64px"></v-img>
                </v-card>
              </v-list-item-action>
              <v-list-item-content style="max-height:81px">
                <v-list-item-title class="entry-title">{{ title }}</v-list-item-title>
                <v-list-item-subtitle>{{ stringFormatDate(date).substring(0, 17) }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <page-footer :total="entryCount"></page-footer>
    <edit-dialog v-if="isAdmin" ref="editDialog" :id="selectedId" @updated="updateRecord" @removed="loadResults"></edit-dialog>
    <v-dialog v-model="downloading" width="400" persistent>
      <v-card>
        <v-card-title>Downloading...</v-card-title>
        <v-card-text>Downloading the file. This should only take a few seconds...</v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<style>
.entry-card:hover .entry-title {
  white-space: normal;
}
</style>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
import axios from 'axios'

export default {
  components: {
    EditDialog: () => import('@/components/library/archives/entryDialog'),
    PageFooter: () => import('@/components/library/archives/footer')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => user.value.roles && user.value.roles.filter((role) => role === 'Library' || role === 'Technology Services').length > 0)
    const view = computed({
      get: () => root.$store.state.library.archives.view,
      set: (view) => root.$store.commit('library/setArchiveView', view)
    })
    const category = computed({
      get: () => root.$store.state.library.archives.search.category,
      set: (category) => root.$store.commit('library/setArchiveSearch', { category })
    })
    const search = computed({
      get: () => root.$store.state.library.archives.search.text,
      set: (text) => root.$store.commit('library/setArchiveSearch', { text })
    })
    const searchField = computed({
      get: () => root.$store.state.library.archives.search.field,
      set: (field) => root.$store.commit('library/setArchiveSearch', { field })
    })
    const searchLabel = computed(() => {
      return searchField.value === 'title' ? 'Title and Author Search' : 'Full Text Search'
    })
    const capstoneMajor = computed({
      get: () => root.$store.state.library.archives.search.capstoneMajor,
      set: (capstoneMajor) => root.$store.commit('library/setArchiveSearch', { capstoneMajor })
    })
    const capstoneMajors = ref([])
    const yearFilter = computed({
      get: () => root.$store.state.library.archives.search.year,
      set: (year) => root.$store.commit('library/setArchiveSearch', { year })
    })
    const yearOptions = ref([])
    const categories = computed(() => {
      const arr = root.$store.state.library.archives.categories.filter(({ active, loginRequired }) => active && (!loginRequired || root.$store.state.loggedIn)).map(({ name, label }) => { return { value: name, text: label } })
      arr.splice(0, 0, { text: 'All Categories', value: 'All' })
      return arr
    })

    onMounted(async () => {
      if (categories.value.length <= 1) {
        await root.$store.dispatch('library/loadArchiveCategories')
      }
      await root.$store.commit('library/setArchiveSearch', { category: root.$route.params.category.replaceAll('_', ' ') })
      loading.value = false
      loadResults()
      if (root.$store.state.loggedIn) {
        // Load the capstone majors
        const aggregate = [
          { $match: { category: 'Capstone', permission: 'Campus' } },
          { $unwind: '$major' },
          { $group: { _id: { id: '$_id', major: '$major' } } },
          { $group: { _id: '$_id.major', count: { $sum: 1 } } },
          { $sort: { _id: 1 } }
        ]
        if (isAdmin.value) aggregate[0].$match.permission = { $in: ['Public', 'Campus'] }
        const { data } = await root.$feathers.service('library/archive/entry').find({ query: { aggregate } })
        capstoneMajors.value = data.map(({ _id, count }) => { return { text: _id, value: _id, count } })
      } else if (root.$route.params.category !== 'All') {
        const { data } = await root.$feathers.service('library/archive/category').find({ query: { name: root.$route.params.category.replaceAll('_', ' ') } })
        if (data.length > 0) {
          if (data[0].loginRequired) {
            root.$store.commit('setLoginRedirectUrl', root.$route.path)
            root.$router.push('/login')
          }
        }
      }
    })

    const page = computed(() => root.$store.state.library.archives.pagination.page || 1)
    const itemsPerPage = computed(() => root.$store.state.library.archives.pagination.itemsPerPage || 12)
    const sortBy = computed({
      get: () => root.$store.state.library.archives.pagination.sortBy || ['date'],
      set: (sortBy) => root.$store.commit('library/setArchivePagination', { sortBy })
    })
    const sortDesc = computed({
      get: () => root.$store.state.library.archives.pagination.sortDesc || [true],
      set: (sortDesc) => root.$store.commit('library/setArchivePagination', { sortDesc })
    })

    const loading = ref(null)
    const entries = ref([])
    const entryCount = ref(0)
    const headers = computed(() => {
      const arr = []
      if (category.value == null || category.value !== 'Capstone') arr.push({ text: 'Thumbnail', value: 'thumbnail' })
      if (category.value == null || category.value === '' || category.value === 'All') arr.push({ text: 'Category', value: 'category' })
      if (category.value === 'All' || category.value === 'Academic Catalog' || category.value === 'Bagpipe' || category.value === 'Buildings' || category.value === 'Chapel') arr.push({ text: 'Sub-Category', value: 'subcategory' })
      arr.push({ text: 'Title', value: 'title' })
      arr.push({ text: 'Date', value: 'date' })
      if (!(['Academic Catalog', 'Bagpipe', 'Capstone'].includes(category.value))) arr.push({ text: 'Description', value: 'description' })
      arr.push({ text: 'Author', value: 'author' })
      if (category.value !== 'Capstone') arr.push({ text: 'Format', value: 'format' })
      if (isAdmin.value) arr.push({ text: 'Actions', value: 'action' })
      else arr.push({ text: 'View', value: 'action' })
      return arr
    })
    function loadResults () {
      if (loading.value == null) return
      loading.value = true
      entries.value = []
      const query = {
        $select: ['thumbnail', 'category', 'subcategory', 'title', 'date', 'description', 'author', 'format', 'fileLink']
      }
      if (!('username' in user.value)) {
        // User is not logged in
        query.permission = 'Public'
      } else if (user.value.roles.filter((role) => role === 'Library' || role === 'Academic Affairs' || role === 'Institutional Reasearch').length === 0) {
        query.permission = { $in: ['Public', 'Campus'] }
      }
      if (root.$route.params.category !== 'All') {
        query.category = root.$route.params.category.replaceAll('_', ' ')
      } else if (category.value != null && category.value !== '' && category.value !== 'All') {
        query.category = category.value
      }
      if (search.value != null && search.value !== '') {
        query.$text = { $search: search.value }
      }
      if (category.value === 'Capstone' && capstoneMajor.value.length > 0) {
        query.major = { $in: capstoneMajor.value }
      }
      if (yearFilter.value.length > 0) {
        query.year = { $in: yearFilter.value }
      }
      query.$limit = itemsPerPage.value
      query.$skip = (page.value - 1) * itemsPerPage.value
      query.$sort = {}
      for (let i = 0; i < sortBy.value.length; i++) {
        query.$sort[sortBy.value[i]] = sortDesc.value[i] ? -1 : 1
      }
      root.$feathers.service('library/archive/entry').find({ query }).then(({ total, data }) => {
        if (total > 0 && data.length === 0) {
          root.$store.commit('library/setArchivePagination', { page: 1 })
        }
        entryCount.value = total
        entries.value = data
        loading.value = false
      })
      if ((search.value != null && search.value !== '') || (capstoneMajor.value != null && capstoneMajor.value !== '')) {
        const aggregate = [
          { $match: { ...query } },
          { $group: { _id: '$year', count: { $sum: 1 } } },
          { $sort: { _id: -1 } }
        ]
        delete aggregate[0].$match.$select
        delete aggregate[0].$match.$limit
        delete aggregate[0].$match.$skip
        delete aggregate[0].$match.$sort
        if ('year' in aggregate[0].$match) {
          delete aggregate[0].$match.year
        }
        // console.log(JSON.stringify(aggregate))
        root.$feathers.service('library/archive/entry').find({ query: { aggregate } }).then(({ data }) => {
          for (let i = 0; i < yearOptions.value.length; i++) {
            const temp = data.filter(({ _id }) => parseInt(_id) === yearOptions.value[i].value)
            const matchCount = temp.length > 0 ? temp[0].count : 0
            const disabled = matchCount === 0
            // console.log(yearOptions.value[i].value, temp, matchCount)
            yearOptions.value.splice(i, 1, { ...yearOptions.value[i], matchCount, disabled })
          }
        })
      } else {
        for (let i = 0; i < yearOptions.value.length; i++) {
          yearOptions.value.splice(i, 1, { ...yearOptions.value[i], matchCount: null, disabled: false })
        }
      }
    }
    watch([category, capstoneMajor, yearFilter, page, itemsPerPage, sortBy, sortDesc, capstoneMajor], () => {
      if (loading.value != null && loading.value !== true) loadResults()
    })
    watch(category, async (val) => {
      // Load the academic years available for the selected category
      yearOptions.value = []
      const aggregate = [
        { $match: { category: val } },
        { $group: { _id: '$year', count: { $sum: 1 } } },
        { $sort: { _id: -1 } }
      ]
      if (root.$route.params.category !== 'All' && root.$route.params.category !== val) {
        aggregate[0].$match.category = root.$route.params.category
      } else if (root.$route.params.category === 'All' && (val == null || val === '' || val === 'All')) {
        aggregate.splice(0, 1)
      }
      const { data } = await root.$feathers.service('library/archive/entry').find({ query: { aggregate } })
      yearOptions.value = data.map(({ _id, count }) => {
        const year = parseInt(_id)
        return { text: (year - 1) + ' - ' + year, value: year, count }
      })
    })
    const searchTimeout = ref(null)
    watch(search, () => {
      clearTimeout(searchTimeout.value)
      if (loading.value == null || loading.value === true) return
      if (search.value == null) {
        loadResults()
      } else {
        searchTimeout.value = setTimeout(() => { loadResults() }, 700)
      }
    })

    const downloading = ref(false)
    function downloadItem (item, download) {
      // console.log(item)
      if (item.fileLink) {
        if (download) {
          downloading.value = true
          const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
          axios({
            method: 'get',
            url: basePath + '/library/archive/drive-file/' + item.fileLink,
            responseType: 'blob',
            headers: { 'Content-Type': 'multipart/form-data' }
          }).catch((e) => {
            let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error downloading file: ' + e }
            root.$store.dispatch('main/snackbar', snackbar)
          }).then((response) => {
            const blob = new Blob([response.data], { type: response.headers['content-type'] })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = item.title.replaceAll(' ', '_')
            switch (response.headers['content-type']) {
              case 'application/pdf':
                link.download += '.pdf'
                break
              case 'txt':
                link.download += '.txt'
                break
              default:
                link.download += '.mp3'
                break
            }
            link.click()
            downloading.value = false
          })
        } else {
          root.$router.push('/library/archives/' + item._id)
        }
      }
    }

    const editDialog = ref(null)
    const selectedId = ref(null)

    function editItem (item) {
      if (isAdmin.value) {
        selectedId.value = item._id
        editDialog.value.activate()
      }
    }

    function addEntry () {
      selectedId.value = ''
      editDialog.value.activate()
    }

    function updateRecord (obj) {
      if (selectedId.value !== '') {
        for (let i = 0; i < entries.value.length; i++) {
          if (entries.value[i]._id === selectedId.value) {
            entries.value.splice(i, 1, { ...entries.value[i], ...obj })
            break
          }
        }
      }
    }

    return {
      user,
      isAdmin,
      view,
      category,
      search,
      searchField,
      searchLabel,
      capstoneMajor,
      capstoneMajors,
      yearFilter,
      yearOptions,
      categories,
      page,
      itemsPerPage,
      sortBy,
      sortDesc,
      loading,
      entries,
      entryCount,
      headers,
      loadResults,
      downloading,
      downloadItem,
      editDialog,
      selectedId,
      editItem,
      addEntry,
      updateRecord,
      stringFormatDate
    }
  }
}
</script>
